import React from "react";
import { useTranslation } from "react-i18next";
import room from "../assets/threeD/room.png";
import resume from "../assets/threeD/resume.png";
import game from "../assets/threeD/ue1.png";
import { Link } from "react-router-dom";

function Page() {
    const { i18n, t } = useTranslation();
    return (
        <div className="threeDProjectsWrapper">
            <h1>{t("my3DProjects")} (Work in progress)</h1>

            <div className="threeDProjectContainer">
                <Link to="/3d/resume">
                    <div className="threeDProjectImage">
                        <img src={resume} alt="3d resume" />
                    </div>
                </Link>
                <div className="threeDProjectMain">
                    <h2> {t("resume")} </h2>
                    <div className="threeDProjectTop">
                        <p> Spline </p>
                        <p> 2023 </p>
                    </div>
                    <div className="threeDProjectDesc">{t("resumeDesc")}</div>
                    <Link to="/3d/resume">
                        <button className="projectLink">Live demo</button>
                    </Link>
                </div>
            </div>

            <div className="threeDProjectContainer">
                <Link to="/3d/room">
                    <div className="threeDProjectImage">
                        <img src={room} alt="3d room" />
                    </div>
                </Link>
                <div className="threeDProjectMain">
                    <h2> {t("room")} </h2>
                    <div className="threeDProjectTop">
                        <p> Spline </p>
                        <p> 2023 </p>
                    </div>
                    <div className="threeDProjectDesc">{t("roomDesc")}</div>
                    <Link to="/3d/room">
                        <button className="projectLink">Live demo</button>
                    </Link>
                </div>
            </div>

            

            <div className="threeDProjectContainer">
                <Link to="/3d/game">
                    <div className="threeDProjectImage">
                        <img src={game} alt="3d room" />
                    </div>
                </Link>
                <div className="threeDProjectMain">
                    <h2> {t("game")} </h2>
                    <div className="threeDProjectTop">
                        <p> Unreal Engine 5 </p>
                        <p> 2023 </p>
                    </div>
                    <div className="threeDProjectDesc">{t("gameDesc")}</div>
                        <button disabled className="projectLink">Live demo</button>
                </div>
            </div>
        </div>
    );
}

export default Page;
