import React, { useState } from "react";
import Loading from "../components/Loading";
import SideMenu from "../components/Sidemenu";
import Helpbox from "../components/Helpbox";

const Spline = React.lazy(() => import("@splinetool/react-spline"));

function ResumePage() {
    const [isLoaded, setIsLoaded] = useState(false);
    const [selectedScene, setSelectedScene] = useState("highQuality");

    return (
        <React.Suspense fallback={<Loading />}>
            {selectedScene === "highQuality" && (
                <>
                    <Spline
                        scene="https://prod.spline.design/6uC1VamQuPZJ7k62/scene.splinecode"
                        onLoad={() => setIsLoaded(true)}
                    />
                    <Helpbox />
                </>
            )}

            {selectedScene === "performance" && (
                <Spline
                    scene="https://prod.spline.design/TzRjXE97yb4cyqtt/scene.splinecode"
                    onLoad={() => setIsLoaded(true)}
                />
            )}

            {!isLoaded && <Loading />}
            <SideMenu
                selectedScene={selectedScene}
                setSelectedScene={setSelectedScene}
                setIsLoaded={setIsLoaded}
            />
        </React.Suspense>
    );
}

export default ResumePage;
